import React from "react";
import Container from "../../../components/Container";
import classes from "../Contact.module.css";
import {Grid} from "@mui/material";
import Formular from "./Formular";

const Offertanfrage = () => {
    return (
        <Container showNavbar={true}>
            <div className={"content-container"}>
                <Grid container spacing={1}>
                    <Grid item xs={7} style={{height: 750, position: "relative"}}>
                        <div className={classes.dividerTop}></div>
                        <Formular />
                        <div className={classes.divider}></div>
                    </Grid>
                    <Grid item xs={5} style={{height: 750,position: "relative"}}>
                        <div className={classes.dividerTop}></div>
                        <p style={{textAlign: "right"}}>
                            <strong>Angebot</strong>
                            <br/>
                            <br/>
                            <strong>Architektur:</strong>
                            <br/>
                            Professionelle Architektenleistungen ab 80 CHF pro Stunde. Vertrauen Sie unserer
                            Expertise für innovative und maßgeschneiderte architektonische Lösungen. Lassen Sie
                            uns Ihr Projekt gemeinsam verwirklichen.
                            <br/>
                            <br/>
                            <strong>Design:</strong>
                            <br/>
                            Unsere kreativen Designer stehen Ihnen zur Verfügung, um Ihre Visionen zum Leben
                            zu erwecken. Wir bieten professionelle Designleistungen zu einem
                            wettbewerbsfähigen Stundensatz von 80 CHF. Lassen Sie uns gemeinsam Ihre Ideen in
                            beeindruckende Designs verwandeln.
                            <br/>
                            <br/>
                            <strong>Scan:</strong>
                            <br/>
                            Nutzen Sie unsere modernen Scandienstleistungen zu einem attraktiven Stundensatz
                            von 80 CHF. Wir scannen und digitalisieren Gebäude, Fotos oder Objekte nach Ihren
                            Anforderungen. Effizient, präzise und kostengünstig – wir bringen Ihre Unterlagen in
                            die digitale Welt.
                            <br/>
                            <br/>
                            <strong>Drohne:</strong>
                            <br/>
                            Wir erfassen atemberaubende Luftaufnahmen mit unserer Drohne! Unser Angebot
                            bietet hochwertige Foto- und Videoaufnahmen ab nur 80 CHF. Entdecken Sie die Welt
                            aus der Vogelperspektive und lassen Sie uns Ihre einzigartigen Momente in Bild und
                            Video festhalten.
                            <br/>
                            <br/>
                            Nico Specker
                        </p>
                        <div className={classes.divider}></div>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
}

export default Offertanfrage;