import React, {useEffect} from "react";
import Container from "../../components/Container";
import classes from "./Drone.module.css";
import {useNavigate} from "react-router-dom";

const Drone = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/drohne/fotodrohne');
    }, []);

    return (
        <Container showNavbar={true}>
            <div className={classes.droneContainer + " content-container"}>
                <h3>Drone</h3>
            </div>
        </Container>
    );
}

export default Drone;