import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import classes from "./NavbarSecond.module.css";

const NavbarSecond = () => {

    const location = useLocation();
    const [links, setLinks] = useState([]);


    useEffect(() => {
        const {pathname} = location;

        if (pathname.startsWith("/firma")) {
            setLinks([{name: "snio", path: "/firma/snio"}]);
        } else if (pathname.startsWith("/architektur")) {
            setLinks([{name: "Casa Vigne Friedli", path: "/architektur/casa-vigne-friedli"}]);
        } else if (pathname.startsWith("/design")) {
            setLinks([
                {name: "Grafikdesign", path: "/design/grafikdesign"},
                {name: "Corporate Design", path: "/design/corporate-design"}
            ]);
        } else if (pathname.startsWith("/drohne")) {
            setLinks([
                {name: "Fotodrohne", path: "/drohne/fotodrohne"},
                {name: "Videodrohne", path: "/drohne/videodrohne"}
            ]);
        } else if (pathname.startsWith("/scan")) {
            setLinks([
                {name: "Gebäude", path: "/scan/gebaeude"},
                {name: "Objekte", path: "/scan/objekte"}
            ]);
        } else if (pathname.startsWith("/kontakt")) {
            setLinks([
                {name: "Offertanfrage", path: "/kontakt/offertanfrage"},
                {name: "Kontakt", path: "/kontakt"}
            ]);
        } else {
            // Default case
        }


    }, [location.pathname]);

    let navbarLinks = links.map(link => {
        return (
            <React.Fragment>
                <div className={classes.navDivider}></div>
                <Link to={link.path}
                      className={`${classes.navLink} ${location.pathname === link.path ? classes.boldNavLink : ''}`}
                      style={{fontFamily: 'DIN Alternate regular', fontSize: 12}}>
                    {link.name}
                </Link>
            </React.Fragment>
        )
    })

    return (
        <div className={classes.navbar}>
            {navbarLinks}
            <div className={classes.navDivider}></div>
        </div>
    );
};

export default NavbarSecond;
