import React from "react";
import classes from "./Fotodrohne.module.css";
import {Grid} from "@mui/material";
import Container from "../../../components/Container";

const Fotodrohne = () => {
    return (
        <Container showNavbar={true}>
            <div className={"content-container"}>
                <Grid container spacing={1}>
                    <Grid item xs={7}>
                        <div style={{height: 600, overflow: "hidden", width: "100%"}}>
                            <img src={"/images/drohne/drone.jpg"} alt={"drohne"} className={classes.image01}/>
                        </div>
                    </Grid>
                    <Grid item xs={5}>
                        <div className={classes.divider}></div>
                        <p style={{textAlign: "right"}}>
                            <strong>DJI Mavic Air 2s</strong>
                            <br/>
                            <br/>
                            Die DJI Mavic Air 2S ist eine fortschrittliche Drohne, die für Luftaufnahmen und Videografie entwickelt wurde. Sie verfügt über eine beeindruckende Liste von Funktionen und Technologien, die sie zu einer vielseitigen und leistungsstarken Drohne machen.
                            <br/>
                            <br/>
                            Kamera:
                            <br/>
                            Die Mavic Air 2S ist mit einer hochwertigen 1-Zoll CMOS-Kamera ausgestattet, die 20 Megapixel Fotos und 5,4K-Videoaufnahmen bei 30 Bildern pro Sekunde ermöglicht. Dies bedeutet, dass sie gestochen scharfe und detailreiche Bilder und Videos aufnehmen kann.
                            <br/>
                            <br/>
                            Die Drohne verfügt über einen HDR-Modus, der dafür sorgt, dass die Aufnahmen in Situationen mit starkem Kontrast gut aussehen. Sie kann sowohl helle als auch dunkle Bereiche im Bild ausgleichen.
                            <br/>
                            <br/>
                            Intelligente Funktionen:
                            <br/>
                            Die Drohne verfügt über Hinderniserkennungssensoren in alle Richtungen, die dazu beitragen, Kollisionen zu vermeiden. Sie kann autonom fliegen und Hindernisse umgehen.
                            <br/>
                            <br/>
                            Flugzeit:
                            <br/>
                            Die Mavic Air 2S bietet eine beeindruckende Flugzeit von bis zu 31 Minuten pro Akkuladung. Dies ermöglicht längere Flüge und mehr Zeit für Aufnahmen.

                        </p>
                        <div className={classes.divider}></div>
                    </Grid>
                    <Grid item xs={5} style={{ height: '300px', overflow: 'hidden' }}>
                        <img src={"/images/drohne/drohne_aufnahme_1.jpg"} alt={"image_02"} className={classes.image02} />
                    </Grid>
                    <Grid item xs={2} style={{ height: '300px', overflow: 'hidden' }}>
                        <img src={"/images/drohne/drohne_aufnahme_2.jpg"} alt={"image_03"} className={classes.image03} />
                    </Grid>

                    <Grid item xs={6}/>
                </Grid>
            </div>
        </Container>
    );
}

export default Fotodrohne;