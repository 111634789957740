import React from "react";
import classes from "./Videodrohne.module.css";
import {Grid} from "@mui/material";
import Container from "../../../components/Container";

const Videodrohne = () => {
    return (
        <Container showNavbar={true}>
            <div className={"content-container"}>
                <Grid container spacing={1}>
                    <Grid item xs={7}>
                        <div style={{ height: 600, overflow: "hidden", width: "100%" }}>
                            <img
                                src={"/images/drohne/drone_2.png"}
                                alt={"drohne"}
                                className={classes.image01}
                                style={{ objectPosition: 'right', objectFit: 'cover', width: '100%', height: '100%' }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={5}>
                        <div className={classes.divider}></div>
                        <p style={{textAlign: "right"}}>
                            <strong>GEPRC CineLog 35 O3
                            </strong>
                            <br/>
                            <br/>
                            Die GepRC CineLog 35 V2 O3 ist eine FPV-Drohne, die speziell für das Fliegen und Aufnehmen von beeindruckenden Luftaufnahmen und Videos entwickelt wurde. Diese Drohne zeichnet sich durch ihre hochwertige Bauweise und leistungsstarke Komponenten aus.
                            <br/>
                            <br/>
                            Unsere beeindruckende FPV-Drohnenkamera ist mit einer fortschrittlichen 5.3K- Videofunktion und einem beeindruckenden 1/1,9-Zoll-CMOS-Sensor ausgestattet, um Ihnen ein unvergleichliches Flugerlebnis und Aufnahmequalität zu bieten.
                            <br/>
                            <br/>
                            <strong>Ultra-Hochauflösendes 5.3K-Video:</strong>
                            <br/>
                            Die Videokamera unserer FPV-Drohne ermöglicht atemberaubende Videoaufnahmen in erstaunlicher 5.3K-Auflösung. Tauchen Sie ein in eine Welt gestochen scharfer Details und lebendiger Farben. Ihre Aufnahmen werden lebensechter denn je.
                            <br/>
                            <br/>
                            <strong>1/1,9-Zoll-CMOS-Sensor:</strong>
                            <br/>
                            Der 1/1,9-Zoll-CMOS-Sensor ist das Herzstück unserer Kamera und ermöglicht die Erfassung von klaren und rauscharmen Aufnahmen, selbst bei schwierigen Lichtverhältnissen. Er sorgt für eine hervorragende Bildqualität und eine beeindruckende Dynamik.
                            <br/>
                            <br/>
                            <strong>HDR (High Dynamic Range):</strong>
                            <br/>
                            Damit können Sie beeindruckende Kontraste und Farbnuancen erfassen, die Ihre Videos und Fotos auf das nächste Level heben.

                        </p>
                        <div className={classes.divider}></div>
                    </Grid>
                    <Grid item xs={5} style={{ height: '300px', overflow: 'hidden' }}>
                        <img src={"/images/drohne/drohne_aufnahme_1.jpg"} alt={"image_02"} className={classes.image02} />
                    </Grid>
                    <Grid item xs={2} style={{ height: '300px', overflow: 'hidden' }}>
                        <img src={"/images/drohne/drohne_aufnahme_2.jpg"} alt={"image_03"} className={classes.image03} />
                    </Grid>
                    <Grid item xs={6}/>
                </Grid>
            </div>
        </Container>
    );
}

export default Videodrohne;