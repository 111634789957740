import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {createBrowserRouter, Navigate, Route, RouterProvider, Routes} from "react-router-dom";
import Company from "./routes/company/Company";
import Portrait from "./routes/portrait/Portrait";
import Design from "./routes/design/Design";
import Architecture from "./routes/architecture/Architecture";
import "./fonts/DINAlternateBold.otf";
import "./fonts/DINAlternateLight.ttf";
import "./fonts/DINAlternateRegular.otf";
import Employees from "./routes/employees/Employees";
import Drone from "./routes/drone/Drone";
import Landingpage from "./routes/landingpage/Landingpage";
import Scan from "./routes/scan/Scan";
import Contact from "./routes/contact/Contact";
import Offertanfrage from "./routes/contact/offertanfrage/Offertanfrage";
import Fotodrohne from "./routes/drone/fotodrohne/Fotodrohne";
import Videodrohne from "./routes/drone/videodrohne/Videodrohne";
import Grafikdesign from "./routes/design/grafikdesign/Grafikdesign";
import Gebaeude from "./routes/scan/gebaeude/Gebaeude";

const router = createBrowserRouter([
    {path: '/', element: <Landingpage/>},
    {
        path: '/firma/*',
        element: (
            <Routes>
                <Route
                    path="/"
                    element={<Navigate to="/firma/snio" replace />}
                />
                <Route path="snio" element={<Company />} />
            </Routes>
        ),
    },
    {path: '/firma/snio', element: <Company/>},
    {path: '/mitarbeiter', element: <Employees/>},
    {path: '/portrait', element: <Portrait/>},
    {
        path: '/architektur/*',
        element: (
            <Routes>
                <Route
                    path="/"
                    element={<Navigate to="/architektur/casa-vigne-friedli" replace />}
                />
                <Route path="casa-vigne-friedli" element={<Architecture />} />
            </Routes>
        ),
    },
    {path: '/design', element: <Design/>},
    {path: '/design/grafikdesign', element: <Grafikdesign/>},
    {path: '/design/corporate-design', element: <Grafikdesign/>},
    {path: '/scan', element: <Scan/>},
    {path: '/scan/gebaeude', element: <Gebaeude/>},
    {path: '/scan/objekte', element: <Scan/>},
    {path: '/drohne', element: <Drone/>},
    {path: '/drohne/fotodrohne', element: <Fotodrohne/>},
    {path: '/drohne/videodrohne', element: <Videodrohne/>},
    {path: '/kontakt', element: <Contact/>},
    {path: '/kontakt/offertanfrage', element: <Offertanfrage/>},
    {path: '*', element: <Landingpage/>}
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);
