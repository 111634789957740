import React from "react";
import classes from "./Company.module.css";
import Container from "../../components/Container";
import {Grid} from "@mui/material";

const Company = () => {
    return (
        <Container showNavbar={true}>
            <div className={classes.companyContainer + " content-container"}>
                <Grid container spacing={1}>
                    <Grid item xs={7}>
                        <div style={{height: 700, overflow: "hidden"}}>
                            <img src={"/images/company/company_image_01.jpg"} alt={"company_image_01"}
                                 className={classes.image01}/>
                        </div>
                    </Grid>
                    <Grid item xs={5}>
                        <div className={classes.divider}></div>
                        <p style={{textAlign: "right", height: "90%"}}>
                            <strong>Über mich</strong>
                            <br/>
                            <br/>
                            Ich bin ein leidenschaftlicher Zeichner und kreativer Profi mit einem breiten Spektrum
                            an Fähigkeiten und Erfahrungen. Mein Herz schlägt für Architektur und Design, und
                            ich widme mich leidenschaftlich der Schaffung von inspirierenden und funktionalen
                            Räumen.
                            <br/>
                            <br/>
                            Als Zeichner bin ich spezialisiert auf das Entwerfen und Visualisieren von Projekten.
                            Ich verwende fortschrittliche Rendering-Techniken, um visionäre Konzepte in
                            beeindruckende visuelle Darstellungen zu verwandeln. Diese Fähigkeit ermöglicht es
                            meinen Kunden, ihre Projekte in vollem Glanz zu sehen, bevor sie in die Realität
                            umgesetzt werden.
                            <br/>
                            <br/>
                            Darüber hinaus bin ich ein erfahrener Drohnenpilot und Videograph. Mit meinen
                            fliegenden Fähigkeiten und meinem Auge für Ästhetik erfasse ich beeindruckende
                            Luftaufnahmen von Architekturprojekten und schaffe fesselnde Videos, die die
                            Schönheit und Einzigartigkeit jedes Projekts hervorheben.
                            Ein weiteres Talent, das ich in meine Arbeit einbringe, ist die Fähigkeit, Objekte in
                            präzisen 3D-Scans zu erfassen. Dies ist besonders nützlich für Architekten und
                            Designer, um genaue Modelle und Referenzen zu erstellen.
                            <br/>
                            <br/>
                            Insgesamt bin ich ein vielseitiger Profi, der die Welt der Architektur, Drohnenflug,
                            Videografie und 3D-Scanning miteinander verknüpft, um kreative und eindrucksvolle
                            Ergebnisse zu erzielen. Meine Leidenschaft für Design und visuelle Ästhetik treibt
                            mich an, immer nach neuen Wegen zu suchen, um die Welt um uns herum zu
                            verschönern und zu verbessern.
                        </p>
                        <p style={{textAlign: "right"}}>Nico Specker</p>
                        <div className={classes.divider}></div>
                    </Grid>
                    <Grid item xs={4}>
                        <div style={{height: 300, overflow: "hidden", width: "100%"}}>
                            <img src={"/images/company/company_image_02.jpg"} alt={"company_image_02"}
                                 className={classes.image02}/>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div style={{height: 300, overflow: "hidden", width: "100%"}}>
                            <img src={"/images/company/company_image_03.jpg"} alt={"company_image_03"}
                                 className={classes.image03}/>
                        </div>
                    </Grid>
                    <Grid item xs={5}>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
}

export default Company;