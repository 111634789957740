import React from "react";
import classes from "./Landingpage.module.css";
import Container from "../../components/Container";

const Landingpage = () => {
    return (
        <Container showNavbar={false}>
            <div>
                {
                    // TODO: correct image
                 }
                <img src={"/images/landingpage/image_01.jpg"} alt={"image_01"} className={classes.mainImage}/>
            </div>
        </Container>
    );
}

export default Landingpage;