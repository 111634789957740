import * as React from 'react';
import classes from './Footer.module.css';
import {Grid} from "@mui/material";

const Footer = () => {

    return (
        <footer>
            <Grid container spacing={1} className={classes.footerContent}>
                <Grid item xs={12} style={{paddingRight: 15}}>
                    <p style={{textAlign: "right"}}><strong>snio</strong> | Architecture | Design | Scan | Drone | Im Bad 16 | 9542 Münchwilen | 079 422 33 35</p>
                </Grid>
                <Grid item xs={12}>
                    {
                        /* TODO: <p>&copy; 2023 snio. All rights reserved.</p>
                    <ul className={classes.footerLinks}>
                        <li><a href="#">Privacy Policy</a></li>
                        <li><a href="#">Terms of Service</a></li>
                        <li><a href="#">Contact Us</a></li>
                    </ul>
                    */}
                </Grid>
            </Grid>
        </footer>
    );
}

export default Footer;