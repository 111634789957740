import React from "react";
import Container from "../../components/Container";
import classes from "./Employees.module.css";

const  Employees = () => {
    return (
        <Container showNavbar={true}>
            <div className={classes.designContainer + " content-container"}>
                <h3>Mitarbeiter</h3>
            </div>
        </Container>
    );
}

export default Employees;