import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import classes from "./Navbar.module.css";
import {Grid} from "@mui/material";
import NavbarSecond from "../navbar-second/NavbarSecond";

const Navbar = () => {

    const location = useLocation();

    return (
        <Grid container spacing={2} className={classes.navbar}>
            <Grid item xs={6}>
                <div className={classes.navDivider}></div>
                <Link to="/firma"
                      className={`${classes.navLink} ${location.pathname.startsWith('/firma') ? classes.boldNavLink : ''}`}>
                    Firma
                </Link>
                <div className={classes.navDivider}></div>
                <Link to="/firma"
                      className={`${classes.navLink} ${location.pathname.startsWith('/portrait') ? classes.boldNavLink : ''}`}>
                    -
                </Link>
                <div className={classes.navDivider}></div>
                <Link to="/architektur"
                      className={`${classes.navLink} ${location.pathname.startsWith('/architektur') ? classes.boldNavLink : ''}`}>
                    Architektur
                </Link>
                <div className={classes.navDivider}></div>
                <Link to="/architektur"
                      className={`${classes.navLink} ${location.pathname.startsWith('/architektur') ? classes.boldNavLink : ''}`}>
                    -
                </Link>
                <div className={classes.navDivider}></div>
                <Link to="/design"
                      className={`${classes.navLink} ${location.pathname.startsWith('/design') ? classes.boldNavLink : ''}`}
                >
                    Design
                </Link>
                <div className={classes.navDivider}></div>
                <Link to="/design"
                      className={`${classes.navLink} ${location.pathname.startsWith('/design') ? classes.boldNavLink : ''}`}>
                    -
                </Link>
                <div className={classes.navDivider}></div>
                <Link to="/scan"
                      className={`${classes.navLink} ${location.pathname.startsWith('/scan') ? classes.boldNavLink : ''}`}
                >
                    Scan
                </Link>
                <div className={classes.navDivider}></div>
                <Link to="/scan"
                      className={`${classes.navLink} ${location.pathname.startsWith('/scan') ? classes.boldNavLink : ''}`}
                >
                    -
                </Link>
                <div className={classes.navDivider}></div>
                <Link to="/drohne/fotodrohne"
                      className={`${classes.navLink} ${location.pathname.startsWith('/drohne') ? classes.boldNavLink : ''}`}
                >
                    Drohne
                </Link>
                <div className={classes.navDivider}></div>
                <Link to="/drohne/fotodrohne"
                      className={`${classes.navLink} ${location.pathname.startsWith('/drohne') ? classes.boldNavLink : ''}`}>
                    -
                </Link>
                <div className={classes.navDivider}></div>
                <Link to="/kontakt/offertanfrage"
                      className={`${classes.navLink} ${location.pathname.startsWith('/kontakt') ? classes.boldNavLink : ''}`}
                >
                    Kontakt
                </Link>
                <div className={classes.navDivider}></div>
            </Grid>
            <Grid item xs={6}>
                <NavbarSecond/>
            </Grid>
        </Grid>
    );
};

export default Navbar;
