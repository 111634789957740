import React from "react";
import Container from "../../components/Container";
import classes from "./Contact.module.css";
import {Grid} from "@mui/material";
import {Outlet} from "react-router-dom";

const Contact = () => {
    return (
        <Container showNavbar={true}>
            <div className={"content-container"}>
                <Grid container spacing={1}>
                    <Grid item xs={7} style={{width: "100%", height: 650, overflow: "hidden"}}>
                        <img src={"/images/company/company_image_01.jpg"} alt={"company_image_01"} className={classes.image01}/>
                    </Grid>
                    <Grid item xs={5} style={{height: 650, position: "relative"}}>
                        <div className={classes.divider}></div>
                        <p style={{textAlign: "right"}}>Kontaktdaten</p>
                        <p style={{textAlign: "right"}}>
                            <strong>snio</strong>
                            <br />
                            Architecture | Design | Scan | Drone
                            <br />
                            <br />
                            Nico Specker
                            <br />
                            Im Bad 16
                            <br />
                            9542 Münchwilen
                            <br />
                            <br />
                            info@snio.ch
                            <br />
                            079 422 33 35
                        </p>
                        <div className={classes.divider}></div>
                    </Grid>
                    <Grid item xs={4}>
                        <div style={{height: 300, overflow: "hidden", width: "100%"}}>
                            <img src={"/images/company/company_image_02.jpg"} alt={"company_image_02"}
                                 className={classes.image02}/>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div style={{height: 300, overflow: "hidden", width: "100%"}}>
                            <img src={"/images/company/company_image_03.jpg"} alt={"company_image_03"}
                                 className={classes.image03}/>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
}

export default Contact;