import React from "react";
import classes from "./Gebaeude.module.css";
import {Grid} from "@mui/material";
import Container from "../../../components/Container";

const  Gebaeude = () => {
    return (
        <Container showNavbar={true}>
            <div className={"content-container"}>
                <Grid container spacing={1}>
                    <Grid item xs={7} style={{width: "100%", height: 800, overflow: "hidden"}}>
                        <img src={"/images/scan/gebaeude.png"} alt={"company_image_01"} className={classes.image01}/>
                    </Grid>
                    <Grid item xs={5} style={{height: 800, position: "relative"}}>
                        <div className={classes.divider}></div>
                        <p style={{textAlign: "right", fontWeight: "bold"}}>Drohnenscan</p>
                        <p style={{textAlign: "right"}}>
                            Bei snio nutzen wir modernste Drohnen- und Scan-Technologien, um Gebäude in beeindruckender Detailgenauigkeit zu erfassen und 3D-Modelle zu erstellen. Entdecken Sie, wie wir die Zukunft der Baubranche gestalten:
                        </p>
                        <p style={{textAlign: "right", fontWeight: "bold"}}>Unsere Mission:</p>
                        <p style={{textAlign: "right"}}>
                            Unsere Mission ist es, die Art und Weise zu revolutionieren, wie Gebäude erfasst und dokumentiert werden. Mit Hilfe von Drohnen und fortschrittlichen Laserscannern machen wir es möglich, Gebäude in kürzester Zeit in 3D-Modelle umzuwandeln. Dies spart Zeit und Kosten in der Bauplanung und ermöglicht eine präzisere Erfassung von Gebäuden.
                        </p>
                        <p style={{textAlign: "right", fontWeight: "bold"}}>Unsere Leistungen:</p>
                        <p style={{textAlign: "right"}}>
                            Drohnenscanning: Unsere Drohnen sind mit hochpräzisen Laserscannern ausgestattet, die Gebäude aus der Luft erfassen und millimetergenaue 3D-Modelle erstellen.
                        </p>
                        <p style={{textAlign: "right"}}>
                            BIM-Integration: Unsere 3D-Modelle können nahtlos in Building Information Modeling (BIM)-Software integriert werden, um effiziente Planung und Zusammenarbeit zu ermöglichen.
                        </p>
                        <p style={{textAlign: "right", fontWeight: "bold"}}>Warum wir:</p>
                        <p style={{textAlign: "right"}}>
                            Präzision: Unsere Technologie bietet unübertroffene Präzision und Genauigkeit bei der Erfassung von Gebäuden.
                        </p>
                        <p style={{textAlign: "right"}}>
                            Effizienz: Wir sparen Ihnen Zeit und Geld, indem wir schnelle Scans durchführen und umfassende 3D-Modelle erstellen.
                        </p>
                        <p style={{textAlign: "right"}}>
                            Innovation: Wir sind Vorreiter in der Anwendung von Drohnen- und Scan-Technologien in der Baubranche.
                        </p>
                        <p style={{textAlign: "right"}}>
                            Vielseitigkeit: Unsere Lösungen eignen sich für Architekten, Bauingenieure, Immobilienentwickler und viele andere.
                        </p>
                        <p style={{textAlign: "right", fontWeight: "bold"}}>
                            Kontaktieren Sie uns:
                        </p>
                        <p style={{textAlign: "right"}}>
                            Möchten Sie mehr über unsere Dienstleistungen erfahren oder ein Projekt besprechen? Zögern Sie nicht, uns zu kontaktieren.
                        </p>
                        <div className={classes.divider}></div>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
}

export default Gebaeude;