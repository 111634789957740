// Formular.js
import React, { useState } from 'react';
import styles from './Formular.module.css'; // Import the module CSS file

function Formular() {
  const [selectedOption, setSelectedOption] = useState('Architektur');
  const [formData, setFormData] = useState({
    Firma: '',
    VornameNachname: '',
    Adresse: '',
    Email: '',
    Telefon: '',
    Betreff: '',
    Nachricht: '',
  });

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData);
  };

  return (
      <div className={styles.formContainer}>
        <p>Offertanfrage</p>
        <form onSubmit={handleSubmit}>
          <div className={styles.formRow}>
            <label className={styles.formLabel}>
              Thema:
            </label>
              <div className={styles.radioOptions}>
                <label>
                  <input
                      type="radio"
                      name="options"
                      value="Architektur"
                      checked={selectedOption === 'Architektur'}
                      onChange={handleOptionChange}
                  />
                  Architektur
                </label>
                <label>
                  <input
                      type="radio"
                      name="options"
                      value="Design"
                      checked={selectedOption === 'Design'}
                      onChange={handleOptionChange}
                  />
                  Design
                </label>
                <label>
                  <input
                      type="radio"
                      name="options"
                      value="Scan"
                      checked={selectedOption === 'Scan'}
                      onChange={handleOptionChange}
                  />
                  Scan
                </label>
                <label>
                  <input
                      type="radio"
                      name="options"
                      value="Drohne"
                      checked={selectedOption === 'Drohne'}
                      onChange={handleOptionChange}
                  />
                  Drohne
                </label>
              </div>
          </div>
          <div className={styles.formRow}>
            <label className={styles.formLabel}>Firma</label>
            <input
                type="text"
                name="Firma"
                value={formData.Firma}
                onChange={handleInputChange}
                className={styles.formInput}
            />
          </div>
          <div className={styles.formRow}>
            <label className={styles.formLabel}>Vorname / Name</label>
            <input
                type="text"
                name="VornameNachname"
                value={formData.VornameNachname}
                onChange={handleInputChange}
                className={styles.formInput}
            />
          </div>
          <div className={styles.formRow}>
            <label className={styles.formLabel}>Adresse</label>
            <input
                type="text"
                name="Adresse"
                value={formData.Adresse}
                onChange={handleInputChange}
                className={styles.formInput}
            />
          </div>
          <div className={styles.formRow}>
            <label className={styles.formLabel}>Email</label>
            <input
                type="email"
                name="Email"
                value={formData.Email}
                onChange={handleInputChange}
                className={styles.formInput}
            />
          </div>
          <div className={styles.formRow}>
            <label className={styles.formLabel}>Telefon</label>
            <input
                type="tel"
                name="Telefon"
                value={formData.Telefon}
                onChange={handleInputChange}
                className={styles.formInput}
            />
          </div>
          <div className={styles.formRow}>
            <label className={styles.formLabel}>Betreff</label>
            <input
                type="text"
                name="Betreff"
                value={formData.Betreff}
                onChange={handleInputChange}
                className={styles.formInput}
            />
          </div>
          <div className={styles.formRow}>
            <div className={styles.labelTop}>
              <label className={styles.formLabel}>Nachricht:</label>
            </div>
            <textarea
                name="Nachricht"
                value={formData.Nachricht}
                onChange={handleInputChange}
                rows={5} // Set the textarea to be 5 rows high by default
                className={styles.formTextarea} // Add a new CSS class for the textarea
            />
          </div>
          <div className={styles.formRow}>
            <button type="submit" className={styles.submitButtonOutline}>
              Absenden
            </button>
          </div>
        </form>
      </div>
  );
}

export default Formular;
