import * as React from 'react';
import Header from "./header/Header";
import Navbar from "./navbar/Navbar";
import {Grid} from "@mui/material";
import Footer from "./footer/Footer";

const Container = (props) => {
    return (
        <div>
            <Header title={'Portrait'}/>
            <div className={"container"}>
                {props.showNavbar &&
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <Navbar/>
                        </Grid>
                        <Grid item xs={10}>
                            {props.children}
                        </Grid>
                    </Grid>
                }
                {!props.showNavbar &&
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {props.children}
                        </Grid>
                    </Grid>
                }
            </div>
            <Footer/>
        </div>
    );
}

export default Container;