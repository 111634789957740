import React, {useEffect} from "react";
import Container from "../../components/Container";
import classes from "./Design.module.css";
import {useNavigate} from "react-router-dom";

const  Design = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/design/grafikdesign');
    }, []);

    return (
        <Container showNavbar={true}>
            <div className={classes.designContainer + " content-container"}>
                <h3>Design</h3>
            </div>
        </Container>
    );
}

export default Design;