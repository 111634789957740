import React from "react";
import Container from "../../components/Container";
import classes from "./Portrait.module.css";

const Portrait = () => {
    return (
        <Container showNavbar={true}>
            <div className={classes.portraitContainer + " content-container"}>
                <h3>Portrait</h3>
            </div>
        </Container>
    );
}

export default Portrait;