import React from "react";
import Container from "../../components/Container";
import classes from "./Architecture.module.css";
import {Grid} from "@mui/material";

const Architecture = () => {
    return (
        <Container showNavbar={true}>
            <div className={"content-container"}>
                <Grid container spacing={1}>
                    <Grid item xs={7}>
                        <div style={{height: 600, overflow: "hidden", width: "100%"}}>
                            <img src={"/images/architecture/image_01.jpg"} alt={"image_01"} className={classes.image01}/>
                        </div>

                    </Grid>
                    <Grid item xs={5}>
                        <div className={classes.divider}></div>
                        <p style={{textAlign: "right"}}>
                            <strong>Casa Vigne</strong>
                            <br/>
                            <br/>
                            <strong>Auftraggeber: Markus Friedli, Architekt</strong>
                            <br/>
                            <br/>
                            <strong>Hintergrund:</strong>
                            <br/>
                            Markus Friedli, renommierter Architekt, hatte das Privileg, das atemberaubende
                            Projekt "Casa Vigne Friedli" in Mergoscia zu realisieren. Das Haus, ein
                            architektonisches Meisterwerk, vereint modernes Design mit atemberaubender
                            natürlicher Schönheit und ist ein herausragendes Beispiel für zeitgenössische
                            Schweizer Architektur.
                            <br/>
                            <br/>
                            <strong>Mein Auftrag:</strong>
                            <br/>
                            Der Auftrag für dieses Projekt bestand darin, die Pläne und Bilder von "Casa Vigne
                            Friedli" in einer Architekturzeitschrift ansprechend aufzubereiten und zu gestalten. Die
                            Zeitschrift sollte das Projekt in seiner vollen Pracht präsentieren und die kreative
                            Vision von Markus Friedli als Architekt hervorheben.
                            <br/>
                            <br/>
                            <strong>Das Ergebnis:</strong>
                            <br/>
                            Die Architekturzeitschrift für Casa Vigne Friedli wurde zu einem beeindruckenden
                            Schaufenster für das Projekt. Sie präsentierte nicht nur die architektonischen Details
                            und die Ästhetik des Hauses, sondern vermittelte auch die Leidenschaft und das
                            Engagement von Markus Friedli als Architekt. Die Zeitschrift erhielt positive Resonanz
                            von Lesern und Architekturbegeisterten und trug dazu bei, die Bekanntheit von Casa
                            Vigne Friedli in der Architekturwelt zu steigern.
                            Als Zeichner bin ich spezialisiert auf das Entwerfen und Visualisieren von Projekten.
                            Ich verwende fortschrittliche Rendering-Techniken, um visionäre Konzepte in
                            beeindruckende visuelle Darstellungen zu verwandeln. Diese Fähigkeit ermöglicht es
                            meinen Kunden, ihre Projekte in vollem Glanz zu sehen, bevor sie in die Realität
                            umgesetzt werden.
                        </p>
                        <div className={classes.divider}></div>
                    </Grid>
                    <Grid item xs={3}>
                        <img src={"/images/architecture/image_02.jpg"} alt={"image_02"} className={classes.image02}/>
                    </Grid>
                    <Grid item xs={3}>
                        <img src={"/images/architecture/image_03.jpg"} alt={"image_03"} className={classes.image03}/>
                    </Grid>
                    <Grid item xs={6} />
                    <Grid item xs={3}>
                        <img src={"/images/architecture/image_04.jpg"} alt={"image_04"} className={classes.image04}/>
                    </Grid>
                    <Grid item xs={3}>
                        <img src={"/images/architecture/image_05.jpg"} alt={"image_05"} className={classes.image05}/>
                    </Grid>
                    <Grid item xs={6} />
                </Grid>
            </div>
        </Container>
    );
}

export default Architecture;